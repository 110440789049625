/*===========================
      01.COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css?family=Philosopher:400,700|Poppins:300,400,500,600,700,800&display=swap");
body {
  font-family: "Philosopher", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #ebe6e6;
  overflow-x: hidden;
  background-color: #000;
}

@font-face {
  font-family: "Grotley";
  src: local("Grotley"), url(../../fonts/Demon.ttf) format("truetype");
}

hr {
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

a {
  color: #fff;
}
a:hover {
  color: #fff;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Philosopher", sans-serif;
  font-weight: 700;
  color: #f1eaea;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 2rem;
}

h4 {
  /* font-family:"Grotley"; */
  font-size: 22px;
  line-height: 1;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.5rem;
}

p {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 24px;
  color: #eee;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0 40px;
  font-size: 16px;
  line-height: 48px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #00d3ca;
}
.main-btn:hover {
  background-color: rgba(229, 78, 27, 0.8);
  color: #fff;
}
.main-btn.main-btn-2 {
  background-color: #fff;
  color: #00d3ca;
  border-color: #00d3ca;
}
.main-btn.main-btn-2:hover {
  background-color: #00d3ca;
  border-color: #00d3ca;
  color: #fff;
}

/*===== All Section Title Style =====*/
.section_title .title {
  font-size: 3rem;
  letter-spacing: 0.2rem;
  font-weight: 700;
}

.section_title .line {
  height: 2px;
  width: 150px;
  background-color: #00d3ca;
  border-radius: 50px;
  margin: 30px auto 0;
  position: relative;
}
.section_title .line .box {
  position: absolute;
  top: -6px;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #00d3ca;
  -webkit-transform: translateX(-35%) rotate(45deg);
  -moz-transform: translateX(-35%) rotate(45deg);
  -ms-transform: translateX(-35%) rotate(45deg);
  -o-transform: translateX(-35%) rotate(45deg);
  transform: translateX(-35%) rotate(45deg);
}
.section_title .line .box::before,
.section_title .line .box::after {
  position: absolute;
  content: "";
  top: 7px;
  left: -8px;
  width: 100%;
  height: 100%;
  background-color: rgba(229, 78, 27, 0.33);
}
.section_title .line .box::after {
  left: auto;
  right: -7px;
  top: -8px;
}

.navbar {
  --bs-navbar-padding-y: 0;
}

.navbar-brand {
  text-align: center;
}

.navbar-brand p {
  color: #eee;
}

.nav-link {
  color: #eee;
  margin-top: 40px;
  font-size: 1.3rem;
  font-family: "Philosopher", sans-serif;
}

.nav-link:focus,
.nav-link:hover {
  color: #00d3ca;
}

.mainLogo {
  height: 5rem;
}

.leftLogo {
  height: 3rem;
}

.bookNow {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 2.5rem;
}

.bookNow2 {
  height: 2rem;
}

.bookNow2Link {
  position: relative;
  right: 10px;
}

.bg-dark {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#45484d+0,000000+100;Black+3D+%231 */
  background: #45484d; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #45484d 0%,
    #000000 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #45484d 0%,
    #000000 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #45484d 0%,
    #000000 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

/*===========================
        04.ABOUT css 
===========================*/

.about_content.row {
  display: block;
}

.about_title {
  font-size: 2rem;
  font-weight: 700;
}

.about_text {
  /* text-align: justify; */
  margin-top: 1rem;
}

#about .social,
#gallery .social {
  padding-top: 20px;
}
#about .social li,
#gallery .social li {
  display: inline-block;
  margin-top: 10px;
}
#about .social li + li {
  margin-left: 15px;
}
#gallery .social li {
  margin-right: 15px;
}
#about .social li a,
#gallery .social li a {
  padding: 5px;
  background-color: #7a7a7a;
  color: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#about .social li a:hover,
#gallery .social li a:hover {
  color: #fff;
  background-color: #00d3ca;
}

ul.socialNoMargin,
ul.social {
  padding-left: 0;
}

#gallery .social {
  padding-top: 0;
}

.picCol img {
  float: left;
  width: 40%;
  margin-right: 20px;
}

/*===========================
     10.CONTACT css 
===========================*/
p.form-message.success,
p.form-message.error {
  font-size: 16px;
  color: #2a2a2a;
  background: #f7f7fd;
  padding: 10px 15px;
  margin-top: 30px;
  margin-left: 15px;
}
p.form-message.success.form-message.error,
p.form-message.error.form-message.error {
  color: #f00;
}

.single_form input,
.single_form textarea {
  padding: 0 25px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  height: 60px;
  width: 100%;
  font-size: 16px;
  color: #000;
}
.single_form input:focus,
.single_form textarea:focus {
  border-color: #00d3ca;
}

.single_form textarea {
  height: 180px;
  padding-top: 15px;
  resize: none;
}

.single_form .main-btn {
  font-size: 1.3rem;
  height: 60px;
  line-height: 60px;
  padding: 0 60px;
}

.contact_map .gmap_canvas iframe {
  width: 100%;
  height: 430px;
}

#contact {
  font-size: 1.1rem;
}

.submitMessage {
  font-size: 1.3rem;
  color: #00d3ca;
}

/*===========================
        11.FOOTER css 
===========================*/

#footer {
  clear: both;
}

.clear {
  clear: both;
}

.footer_area {
  background-color: #2a2a2a;
}

.subscribe_title .title {
  color: #fff;
  font-size: 44px;
  font-weight: 700;
}

.subscribe_title p {
  margin-top: 15px;
  color: #fff;
}

.subscribe_form {
  padding-right: 85px;
  position: relative;
}
.subscribe_form input {
  width: 100%;
  height: 55px;
  padding: 0 25px;
  border: 1px solid #fff;
  background: none;
  border-radius: 5px;
  color: #fff;
}
.subscribe_form button {
  width: 55px;
  height: 55px;
  padding: 0;
  background-color: #00d3ca;
  color: #fff;
  border: 0;
  font-size: 28px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

.footer_widget .footer_title {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  position: relative;
  padding-bottom: 25px;
}
.footer_widget .footer_title::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 3px;
  border-radius: 50px;
  background-color: #00d3ca;
  bottom: 0;
  left: 0;
}

.footer_about p,
.footer_link p {
  color: #fff;
  margin-top: 25px;
}

.footer_about .social {
  margin-top: 25px;
}
.footer_about .social li {
  display: inline-block;
}
.footer_about .social li + li {
  margin-left: 10px;
}
.footer_about .social li a {
  padding: 5px;
  background-color: #3d3d3d;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer_about .social li a:hover {
  background-color: #00d3ca;
  color: #fff;
}

.footer_link_wrapper .footer_link:last-child {
  padding-left: 40px;
}

.footer_link_wrapper .footer_link ul {
  padding-top: 15px;
}
.footer_link_wrapper .footer_link ul li {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}
.footer_link_wrapper .footer_link ul li a {
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer_link_wrapper .footer_link ul li a:hover {
  color: #00d3ca;
}

#footer img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
}

.footer_instagram a {
  width: 100%;
}

.footer_copyright .copyright {
  padding: 25px 0;
  border-top: 1px solid rgba(238, 238, 238, 0.33);
}
.footer_copyright .copyright p {
  font-size: 16px;
  color: #fff;
}

.ml-auto {
  margin: 0 auto;
}

#background-video {
  width: 100vw;
  object-fit: cover;
}

.turtles {
  margin-top: 20px;
  height: 3rem;
}

.image-grid-cover {
  width: 100%;
  background-size: cover;
  min-height: 180px;
  position: relative;
  margin-bottom: 30px;
  text-shadow: rgba(0, 0, 0, 0.8) 0 1px 0;
  border-radius: 4px;
}
.image-grid-clickbox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(0, 0, 0, 0.45);
}
.cover-wrapper {
  font-size: 18px;
  text-align: center;
  display: block;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.8) 0 1px 0;
  z-index: 21;
  position: relative;
  top: 80px;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

img.zoom,
img.gridPic,
.lazy-load-image-background.blur.lazy-load-image-loaded img {
  /* margin-bottom:10px; */
  width: 100%;
  /* height: 200px; */
  border-radius: 5px;
  /* object-fit:cover; */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: transform 0.3s;
}

img.gridPic:hover,
.lazy-load-image-background.blur.lazy-load-image-loaded img:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

img.gallery {
  width: 100%;
  border-radius: 5px;
}

.transition {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

#shop .p-card {
  /* border: 1px solid #fff; */
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

#shop .p-details {
  padding: 10px;
  border-radius: 0px;
  background: #333;
  color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#shop .carousel-inner {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#shop .p-details h5,
h6 {
  color: #fff;
}

#shop .p-details button {
  background-color: #00d3ca;
  border-color: #222;
}

#shop .p-details button:hover {
  background-color: #000;
  border-color: #222;
}

#shop .spec span {
  font-size: 13px;
}

#shop .spec h6 {
  font-size: 16px;
  font-weight: 500;
}

#shop .carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
}

#shop hr {
  color: #fff;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 0.2px;
  opacity: 1;
}

#shop .btn-block {
  width: 100%;
}

#shop p {
  color: #fff;
}

.shortMerch {
  text-align: center;
}

.shortMerch img {
  width: 100%;
  border-radius: 5px;
}

.contactError {
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid red;
}

.servicesBorders {
  background-color: rgba(17, 17, 17, 0.8);
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 30px;
}

#services {
  clear: both;
}

.text-sm img {
  margin-top: -2px;
}

#services .card::after {
  display: block;
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: calc(100% - 40px);
  height: 35px;
  background-color: #fff;
  -webkit-box-shadow: 0 19px 28px 5px rgba(64, 64, 64, 0.09);
  box-shadow: 0 19px 28px 5px rgba(64, 64, 64, 0.09);
  content: "";
  z-index: -1;
}

#services button {
  /* font-size:12px; */
  color: #00aca3;
  text-decoration: none;
  background-color: none;
}

#services a,
.card-title {
  color: #00aca3;
}
#services a.card {
  text-decoration: none;
}

#services .card {
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
}
#services .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

#services .box-shadow {
  -webkit-box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09) !important;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09) !important;
}

#services .ml-auto,
.mx-auto {
  margin-left: auto !important;
}
#services .mr-auto,
.mx-auto {
  margin-right: auto !important;
}
#services .rounded-circle {
  border-radius: 50% !important;
}
#services .bg-white {
  background-color: #fff !important;
}

#services .ml-auto,
.mx-auto {
  margin-left: auto !important;
}

#services .mr-auto,
.mx-auto {
  margin-right: auto !important;
}
#services .d-block {
  display: block !important;
}

#services .card-text {
  padding-top: 12px;
  color: #111;
}

/* #services .text-sm {
        font-size: 12px !important;
    } */

#services p,
.p {
  margin: 0 0 16px;
}

#services .card-title {
  margin: 0;
  font-size: 18px;
  font-weight: 900;
}

#services .pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

#services .head-icon {
  margin-top: 18px;
  color: #ff4500;
}

.moreMargin {
  margin-top: 30px;
}

.mt-5-diff {
  margin-top: 20px;
}

.bt-120 {
  padding-bottom: 120px;
}

.thumbex {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  /* background-color: #ddd; */
  /* box-shadow: 0 0 10px rgba(255,255,255, 0.9); */
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #fff;
}

.thumbex img {
  position: relative;
  z-index: 1;
  /* left: 50%; */
  /* top: 50%; */
  height: auto;
  width: 100%;
  /* transform: translate(-50%, -50%); */
  /* backface-visibility: hidden; */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-out;
}
.thumbex span {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  /* padding: 0px 50px 4px; */
  text-align: center;
  font-size: 1rem;
  color: white;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.2px;
}
.thumbex img:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1, 1.1);
}

.servicesIcons {
  width: 90px;
  height: 90px;
  margin-top: -45px;
}

.piercingIcon {
  margin-top: 10px;
}

.designIcon {
  margin-top: 8px;
}

.cafeIcon {
  width: auto;
  height: 76px;
  margin-left: 7px;
  margin-top: 2px;
}

.back-to-top {
  z-index: 10;
}

.back-to-top button {
  width: 55px;
  height: 55px;
  background-color: #00d3ca;
  color: #fff;
  border: 0;
  font-size: 28px;
  border-radius: 5px;
}

.locationCard {
  margin-top: 30px;
}
.locationCard a {
  display: block;
}

.navText {
  display: inline-block;
  margin-left: 10px;
  font-size: 0.8rem;
}

.react-multi-carousel-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.react-multiple-carousel__arrow {
  min-width: 50px;
  min-height: 50px;
  background-color: #fff;
}

.react-multiple-carousel__arrow::before {
  font-size: 24px;
  color: #000;
}

.react-multiple-carousel__arrow:hover {
  background-color: #fff;
}

.carousel-button-group {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}

.carouselArtists {
  position: relative;
  padding-top: 60px;
}

.buttonDisable {
  opacity: 50%;
}

::file-selector-button {
  border: none;
}

.attachmentField input {
  border: 1px solid white;
  border-radius: 5px;
  width: 100%;
}

.bookNowLink {
  position: relative;
}

.promo {
  background-color: #000;
  border: 1px solid rgba(236, 15, 140, 0.2);
  height: 5rem;
}
.promo a {
  color: #ec0f8c;
}

.homeAdjust {
  margin-top: 25px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-box {
  margin-right: 20px;
  width: 25px !important;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.locations-dropdown:hover {
  background-color: #eee;
}

.heroImage {
  object-fit: cover;
  height: calc(100vh - 114px);
}

.clickable-image {
  cursor: pointer;
}

@media (max-width: 468px) {
  .homeAdjust {
    margin-top: 48px;
  }
}

@media (min-width: 992px) {
  .bookNow2 {
    display: none;
  }
  .navbar-brand.leftBrand {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar-brand.middleBrand {
    display: none;
  }
  .bookNow {
    display: none;
  }
  .nav-link {
    margin-top: auto;
  }
  .heroImage {
    height: 40vh;
  }
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 1.1rem;
  }
  .section_title .title {
    font-size: 2.5rem;
  }
  .about_title {
    font-size: 1.5rem;
  }
  .subscribe_title .title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_link_wrapper .footer_link:last-child {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .footer_link_wrapper .footer_link:last-child {
    padding-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer_link_wrapper .footer_link:last-child {
    padding-left: 50px;
  }
}

@media (max-width: 475px) {
  .navText {
    display: block;
    margin-left: 0;
  }
}

@media (max-width: 363px) {
  .bookNow2 {
    height: 1.8rem;
  }
}
